import styled from 'styled-components';

import { Text } from 'components/Text/Text';
import { colors, textStyles } from 'theme/theme';

export const StyledInput = styled.input<{ $isTabbing: boolean }>`
  height: 14px;
  width: 14px;
  border: 0;
  border-radius: 3px;
  background: ${colors.selectionGrey};
  padding: 3px;
  cursor: pointer;
  margin: 0;
  margin-right: 18px;

  ${textStyles.bodySmall}
  &:not(:checked) {
    appearance: none;
  }
  ${(props) => !props.$isTabbing && `outline: 0;`}
`;

export const OptionContainer = styled.div`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const SearchFacetMultiOptionTextTruncate = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
