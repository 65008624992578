import styled from 'styled-components';

import { colors, cssBreakpoints, gutterWidth } from 'theme/theme';

export const FiltersHolder = styled.div`
  display: block;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: block;

    .is-expanded + .is-expanded {
      margin-top: 12px;
    }
  }
`;

export const ClearMobileHolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 16px ${gutterWidth / 2}px 16px ${gutterWidth / 2}px;

  a {
    display: flex;
    flex: 1;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }
`;

export const ClearHolder = styled.div`
  display: none;
  align-items: center;
  height: 40px;
  width: 100%;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: flex;
    margin-bottom: 21px;
  }
`;

export const StyledSearchFiltersSidebar = styled.div`
  width: 100%;

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    position: fixed;
    z-index: 10;
    background-color: ${colors.white};
    top: 172px;
    bottom: 0;
    overflow-y: scroll;
  }
`;
