import { useEffect, useState } from 'react';

import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

type Args = {
  searchFacets: SearchFacet[];
  filtersById: SearchFiltersById;
};

export function useSearchFiltersSidebarCurrentSection({
  searchFacets,
  filtersById,
}: Args) {
  const locationTypeFacet = searchFacets.find(
    (facet) => facet.id === 'locationTypeFacet',
  );

  const hasLocationType = Boolean(locationTypeFacet);
  const [currentSection, setCurrentSection] = useState<string | null>(
    hasLocationType && filtersById.type !== 'VOLOP'
      ? 'locationType'
      : 'actionType',
  );

  useEffect(() => {
    setCurrentSection(
      hasLocationType && filtersById.type !== 'VOLOP'
        ? 'locationType'
        : 'actionType',
    );
  }, [filtersById.type, hasLocationType]);

  const toggleCurrentSection = (index: string | null) => {
    setCurrentSection(index === currentSection ? null : index);
  };

  return { currentSection, toggleCurrentSection };
}
