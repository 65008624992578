// @ts-expect-error TS(7016): Could not find a declaration file for module 'tz-l... Remove this comment to see the full error message
import tzlookup from 'tz-lookup';

export function getUserTimezone() {
  if (!Intl?.DateTimeFormat) return null;

  const format = Intl.DateTimeFormat();

  if (!format?.resolvedOptions) return null;

  const { timeZone } = format.resolvedOptions();

  return timeZone;
}

export function getTimezoneFromLatLong(lat: number, lon: number): string {
  return tzlookup(lat, lon);
}
