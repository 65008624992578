import { Select } from 'components/Select/Select';
import { SEARCH_RADIUSES } from 'utils/constants/general/searchRadiuses';

const allRadiusOptions = SEARCH_RADIUSES[CURRENT_LOCALE].map(
  ({ id, name }) => ({
    value: id,
    label: name,
  }),
);

type Props = {
  radius: string;
  setSearchRadius: (radius: string) => void;
  includeState: boolean;
  includeCountry: boolean;
};

export function SearchRadiusSelector({
  radius,
  setSearchRadius,
  includeState,
  includeCountry,
}: Props) {
  let radiusOptions = allRadiusOptions;
  if (!includeCountry) {
    radiusOptions = radiusOptions.filter(
      (option) => option.value !== 'country',
    );
  }
  if (!includeState) {
    radiusOptions = radiusOptions.filter((option) => option.value !== 'state');
  }

  return (
    <Select
      label={getText('Search radius')}
      name="radius"
      value={radius}
      options={radiusOptions}
      onChange={(newValue) => newValue && setSearchRadius(newValue.value)}
      data-qa-id="search-radius-selector"
    />
  );
}
