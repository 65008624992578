import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { cssBreakpoints, gutterWidth, shadows } from 'theme/theme';

export const SearchFilterSectionTileBox = styled(
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ children, ...props }: any) => (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...props}>
      <Box position="relative">{children}</Box>
    </Box>
  ),
)<{ $noPadding?: boolean; $noGradient?: boolean }>`
  position: relative;
  padding: ${(props) => (props.$noPadding ? '0' : `16px ${gutterWidth / 2}px`)};

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    padding: ${(props) => (props.$noPadding ? '0' : '16px 0')};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 40px);
      height: calc(100% + 2px);
      background: white;
      border-radius: 4px;
      box-shadow: ${shadows.low};
      opacity: 0;
    }
    ${(props) =>
      props.$expanded && !props.$noGradient
        ? `
      &::before {
        opacity: 1;
      }
    `
        : `
    `}
  }
`;
