import styled from 'styled-components';

import { FieldLabel } from 'components/Form/Field/FieldLabel';

export const StyledLabel = styled(FieldLabel)`
  & + * {
    display: block;
    margin-top: 6px !important;
  }
`;
