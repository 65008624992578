import styled, { createGlobalStyle } from 'styled-components';

import { colors } from 'theme/theme';

export const DatePickerPopper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;

export const DatePickerStyles = createGlobalStyle`
  .react-datepicker-popper {
    width: max-content;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .react-datepicker__day--selected {
    background-color: ${colors.brandBlue} !important;
  }
`;
