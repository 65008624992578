export function isPointGeotype(geoType: string | null | undefined) {
  return (
    geoType &&
    ![
      'country',
      'administrative_area_level_1',
      'colloquial_area',
      'undefined_country_geocodes',
    ].includes(geoType)
  );
}
