import { isPointGeotype } from 'modules/search/helpers/isPointGeotype';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';

import { SearchFiltersSidebarLocationFacet } from './Facet/Location/SearchFiltersSidebarLocationFacet';
import { SearchFiltersSidebarFacetSwitch } from './Facet/SearchFiltersSidebarFacetSwitch';

type Props = {
  filtersById: SearchFiltersById;
  setSearchFilter: (
    facetId: SearchFacetId,
    value?: string | Record<string, unknown> | null,
  ) => void;
  toggleCurrentSection: (index: null | string) => void;
  currentSection: string | null;
  selectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  deselectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  searchableFacets: string[];
  hideSearchLocationAndRadius: boolean;
  searchLocationActive?: boolean;
  searchLocation: SearchLocation | null | undefined;
  setSearchLocation:
    | ((
        searchLocation: SearchLocation | null | undefined,
        shouldEnqueueSearch?: boolean | null,
      ) => void)
    | undefined;
  keywords: string[];
  searchRadius: string | undefined;
  setSearchRadius: ((searchRadius: string) => void) | undefined;
  facetsToRender: SearchFacet[];
};

export function SearchFiltersSidebarFacets({
  filtersById,
  setSearchFilter,
  toggleCurrentSection,
  currentSection,
  selectSearchFilterOption,
  deselectSearchFilterOption,
  selectAllSearchFilterOptions,
  searchableFacets,
  hideSearchLocationAndRadius,
  searchLocationActive = true,
  searchLocation,
  setSearchLocation,
  keywords,
  searchRadius,
  setSearchRadius,
  facetsToRender,
}: Props) {
  const showRadiusSection =
    !hideSearchLocationAndRadius &&
    searchLocation &&
    isPointGeotype(searchLocation.geoType);

  const renderedFacets = facetsToRender.map((facet) =>
    facet.id === 'locationTypeFacet' && hideSearchLocationAndRadius ? null : (
      <SearchFiltersSidebarFacetSwitch
        key={facet.id}
        keywords={keywords}
        facet={facet}
        filtersById={filtersById}
        setSearchFilter={setSearchFilter}
        toggleCurrentSection={toggleCurrentSection}
        currentSection={currentSection}
        selectSearchFilterOption={selectSearchFilterOption}
        deselectSearchFilterOption={deselectSearchFilterOption}
        selectAllSearchFilterOptions={selectAllSearchFilterOptions}
        searchableFacets={searchableFacets}
        searchLocation={searchLocation}
        searchLocationActive={searchLocationActive}
        header={
          facet.id === 'locationTypeFacet' &&
          setSearchLocation && (
            <SearchFiltersSidebarLocationFacet
              key="location"
              filtersById={filtersById}
              hideSearchLocationAndRadius={Boolean(hideSearchLocationAndRadius)}
              searchLocationActive={searchLocationActive}
              searchLocation={searchLocation}
              setSearchLocation={setSearchLocation}
              searchRadius={searchRadius}
              setSearchRadius={setSearchRadius}
              showRadiusSection={Boolean(showRadiusSection)}
            />
          )
        }
      />
    ),
  );

  return <div>{renderedFacets}</div>;
}
