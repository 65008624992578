import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

import { SearchFilterSectionRecencySelect } from './SearchFilterSectionRecencySelect';

type Props = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  setSearchFilter: (facetId: SearchFacetId, value?: string) => void;
  toggleCurrentSection: (index: null | string) => void;
  currentSection: string | null;
};

export function SearchFiltersSidebarRecencyFacet({
  facet,
  filtersById,
  setSearchFilter,
  toggleCurrentSection,
  currentSection,
}: Props) {
  return (
    <SearchFilterSectionRecencySelect
      key={facet.id}
      facet={facet}
      filtersById={filtersById}
      setSearchFilter={setSearchFilter}
      toggleExpanded={() => toggleCurrentSection(facet.name)}
      expanded={currentSection === facet.name}
    />
  );
}
