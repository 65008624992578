// @ts-expect-error TS(7016): Could not find a declaration file for module 'fuzz... Remove this comment to see the full error message
import fuzzysearch from 'fuzzysearch';
import { ChangeEvent, Component, Fragment, ReactNode } from 'react';
import deepEqual from 'react-fast-compare';
import { sprintf } from 'sprintf-js';

import { Box } from 'components/Box';
import { Input } from 'components/Input/Input';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { volopSourceFacetCullFn } from 'modules/search/constants/searchFacets/volopSourceFacet';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { isDefined, isEmpty, sort, sortBy } from 'utils/functional';

import { SearchFacetMultiOption } from './SearchFacetMultiOption';
import { SearchFilterSection } from './SearchFilterSection';

const FACET_OPTIONS_FIRST_SHOWN_COUNT = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allOptionsSelected = ({ filter, facet }: any) =>
  (filter &&
    Array.isArray(filter) &&
    deepEqual(sort(filter), sort(facet.options))) ||
  !filter ||
  filter === 'ALL';

type Props = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  keywords: string[];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSetSearchFilter: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectSearchFilterOption: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeselectSearchFilterOption: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectAllSearchFilterOptions: (...args: Array<any>) => any;
  hasOptionFilter: boolean;
  noToggle?: boolean;
  expanded?: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleExpanded?: (...args: Array<any>) => any;
  header?: ReactNode;
  footer?: ReactNode;
};
type State = {
  optionFilterTerm: string;
};
export class SearchFilterSectionList extends Component<Props, State> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    optionFilterTerm: '',
  };

  updateOptionFilterTerm = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({ optionFilterTerm: ev.target.value });
  };

  renderFacetOption(option: string) {
    const {
      facet,
      filtersById,
      keywords,
      onSetSearchFilter,
      onSelectSearchFilterOption,
      onDeselectSearchFilterOption,
      onSelectAllSearchFilterOptions,
    } = this.props;
    const filter = filtersById[facet.id];

    const getSearchFilterSectionListOptionData = () => {
      if (facet.type !== 'array') {
        return {
          isSelected: filter === option,
          toggle: () => onSetSearchFilter(facet.id, option),
        };
      }

      const isSelected = Boolean(
        (typeof filter === 'string' || Array.isArray(filter)) &&
          filter?.includes(option) &&
          !allOptionsSelected({
            filter,
            facet,
          }),
      );

      return {
        isSelected,
        toggle: () => {
          // If it is being deselected and is the only selected option, select all options
          if (isSelected && deepEqual([option], filter)) {
            onSelectAllSearchFilterOptions(facet.id);
            return;
          }

          if (isSelected) {
            onDeselectSearchFilterOption(facet.id, option);
            return;
          }

          if (
            allOptionsSelected({
              filter,
              facet,
            })
          ) {
            onSetSearchFilter(facet.id, [option]);
            return;
          }

          onSelectSearchFilterOption(facet.id, option);
        },
      };
    };

    const { isSelected, toggle } = getSearchFilterSectionListOptionData();

    if (facet.id === 'locationTypeFacet' && option === 'REMOTE') {
      return (
        <Tooltip
          closeable
          placement="bottom-right"
          renderCondition={keywords.length > 0}
          renderInPortal
          size="large"
          variant="brand-blue"
          content={sprintf(
            getText(
              'We noticed you searched for "%(keywords)s" so we\'ve applied a filter to show you remote positions.',
            ),
            { keywords: keywords.join(', ') },
          )}
        >
          <div style={{ width: 'max-content' }}>
            <SearchFacetMultiOption
              key={option}
              value={option}
              title={facet.optionTitles[option]}
              isSelected={isSelected}
              toggle={() => {
                toggle();

                if (
                  'facetToResetOnChange' in facet &&
                  facet.facetToResetOnChange
                ) {
                  onSelectAllSearchFilterOptions(facet.facetToResetOnChange);
                }
              }}
            />
          </div>
        </Tooltip>
      );
    }

    return (
      <SearchFacetMultiOption
        key={option}
        value={option}
        title={facet.optionTitles[option]}
        isSelected={isSelected}
        toggle={() => {
          toggle();

          if ('facetToResetOnChange' in facet && facet.facetToResetOnChange) {
            onSelectAllSearchFilterOptions(facet.facetToResetOnChange);
          }
        }}
      />
    );
  }

  render() {
    const {
      facet,
      filtersById,
      onSetSearchFilter,
      onSelectAllSearchFilterOptions,
      hasOptionFilter,
      noToggle,
      expanded,
      toggleExpanded,
      header,
      footer,
    } = this.props;

    const { optionFilterTerm } = this.state;

    if ('hideInSideFilter' in facet && facet.hideInSideFilter) return null;

    const filter = filtersById[facet.id];
    let facetOptions = facet.options;

    if (facet.id === 'volopSourceFacet') {
      facetOptions = volopSourceFacetCullFn(filtersById);
    }

    const term = optionFilterTerm.toLowerCase();
    const filteredOptions = isEmpty(term)
      ? facetOptions
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        facetOptions.filter((o: any) =>
          fuzzysearch(term, facet.optionTitles[o].toLowerCase()),
        );

    const optionsOrderedAlphabetically = (options: string[]) =>
      sortBy(options, (o) => facet.optionTitles[o]);

    const optionsOrdered = ({
      options,
    }: {
      options: Array<string>;
    }): Array<string> => {
      if ('sort' in facet && isDefined(facet.sort) && !facet.sort) {
        // facet config says to not sort
        return options;
      }

      return optionsOrderedAlphabetically(options);
    };

    const hasLocationPreferences =
      facet.id === 'locationTypeFacet' && filtersById.type !== 'ORG';

    const showLocationPreferences =
      facet.id !== 'locationTypeFacet' ||
      (facet.id === 'locationTypeFacet' && filtersById.type !== 'ORG');

    return (
      <SearchFilterSection
        title={
          hasLocationPreferences
            ? getText('Location & Remote Preferences')
            : facet.title
        }
        header={
          header ||
          (hasOptionFilter && (
            <Input
              inputProps={{
                value: optionFilterTerm,
                onChange: this.updateOptionFilterTerm,
                placeholder: 'Search',
                id: `side-filter-facet-option-filter-input-${facet.id}`,
                qaId: 'side-filter-facet-option-filter-input',
              }}
              prefix={<InputIconAffix iconName="search" noPaddingRight />}
              affixVariant="transparent"
            />
          ))
        }
        footer={footer}
        noMask={facet.id === 'locationTypeFacet'}
        noToggle={noToggle}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        data-qa-id={`side-filter-facet-${facet.name}`}
        data-qa-filter-value={
          Array.isArray(filter)
            ? sort(filter).join()
            : sort(facetOptions).join()
        }
        count={
          Array.isArray(filter) && filter.length < facet.options.length
            ? filter.length
            : undefined
        }
        hasOptionFilter={hasOptionFilter}
      >
        {showLocationPreferences ? (
          <Box pt={16}>
            <fieldset role="group">
              {facet.type === 'array' ? (
                <SearchFacetMultiOption
                  key="ALL"
                  value="ALL"
                  title={getText('All')}
                  isSelected={allOptionsSelected({
                    filter,
                    facet,
                  })}
                  toggle={() => {
                    if (
                      !allOptionsSelected({
                        filter,
                        facet,
                      })
                    ) {
                      onSelectAllSearchFilterOptions(facet.id);

                      if (
                        'facetToResetOnChange' in facet &&
                        facet.facetToResetOnChange
                      ) {
                        onSelectAllSearchFilterOptions(
                          facet.facetToResetOnChange,
                        );
                      }
                    }
                  }}
                />
              ) : (
                <SearchFacetMultiOption
                  key="ALL"
                  value="ALL"
                  title={getText('All')}
                  isSelected={filter === 'ALL'}
                  toggle={() => {
                    if (filter !== 'ALL') onSetSearchFilter(facet.id, 'ALL');
                  }}
                />
              )}

              {filteredOptions.length <= FACET_OPTIONS_FIRST_SHOWN_COUNT
                ? filteredOptions.map((option) => (
                    <Fragment key={option}>
                      {this.renderFacetOption(option)}
                    </Fragment>
                  ))
                : optionsOrdered({
                    options: filteredOptions,
                  }).map((option) => (
                    <Fragment key={option}>
                      {this.renderFacetOption(option)}
                    </Fragment>
                  ))}
            </fieldset>
          </Box>
        ) : null}
      </SearchFilterSection>
    );
  }
}
