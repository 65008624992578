import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import deepEqual from 'react-fast-compare';

import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { IDEALIST_TIME_ZONE } from 'utils/constants/general/idealistTimeZone';
import { identityFilter, sort as sortFn } from 'utils/functional';
import { getTimezoneFromLatLong, getUserTimezone } from 'utils/timezones';

import { SearchFiltersSidebarFilterPill } from './SearchFiltersSidebarFilterPill';

// Timestamps are stored is seconds in Algolia, must be converted to ms
const fromSecondsTimestamp = (unixTs: number, timezone?: string) =>
  format(toZonedTime(unixTs * 1000, timezone || IDEALIST_TIME_ZONE), 'P');

type Args = {
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  clearFilter: (facetId: SearchFacetId, option: string) => void;
  setFilter: (
    facetId: SearchFacetId,
    value:
      | string
      | { endsGT: string | undefined; startsLT: string | undefined }
      | null
      | undefined,
  ) => void;
  searchLocation: SearchLocation | null | undefined;
  searchLocationActive: boolean;
  jobSynonym: { id: string; name: string } | undefined;
};

export function searchFiltersSidebarMapFacetToPill({
  facet,
  filtersById,
  selectAllSearchFilterOptions,
  clearFilter,
  setFilter,
  searchLocation,
  searchLocationActive,
  jobSynonym,
}: Args) {
  const filter = filtersById[facet.id];

  if (
    Array.isArray(filter) &&
    (facet.id === 'jobFamilyFacet' || filter.length < facet.options.length)
  ) {
    return filter.map((option) => (
      <SearchFiltersSidebarFilterPill
        key={option}
        text={
          facet.id === 'jobFamilyFacet'
            ? `Similar to: ${jobSynonym?.name}`
            : facet.optionTitles[option]
        }
        clearFilter={() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const allOptionsSelected = ({ searchFilter, searchFacet }: any) =>
            (searchFilter &&
              Array.isArray(searchFilter) &&
              deepEqual(sortFn(searchFilter), sortFn(searchFacet.options))) ||
            !searchFilter ||
            searchFilter === 'ALL';

          const isSelected =
            filter &&
            filter.includes(option) &&
            !allOptionsSelected({
              searchFilter: filter,
              searchFacet: facet,
            });

          return isSelected && deepEqual([option], filter)
            ? selectAllSearchFilterOptions(facet.id)
            : clearFilter(facet.id, option);
        }}
      />
    ));
  }

  if (facet.id === 'actionDateFacet') {
    const timezone =
      searchLocationActive &&
      searchLocation?.latitude &&
      searchLocation.longitude
        ? getTimezoneFromLatLong(
            searchLocation.latitude,
            searchLocation.longitude,
          )
        : getUserTimezone();

    const actionDateValue = (filter || {}) as {
      endsGT: string | undefined;
      startsLT: string | undefined;
    };

    return Object.keys(actionDateValue)
      .map((key) =>
        actionDateValue[key as keyof typeof actionDateValue] ? (
          <SearchFiltersSidebarFilterPill
            key={key}
            text={`${
              key === 'endsGT' ? getText('After') : getText('Before')
            }: ${
              // TODO: Fix this the next time the file is edited.
              // @ts-expect-error fix types
              fromSecondsTimestamp(filter[key], timezone)
            }`}
            clearFilter={() =>
              setFilter(facet.id, { ...actionDateValue, [key]: undefined })
            }
          />
        ) : null,
      )
      .filter(identityFilter);
  }

  if (facet.id === 'recencyFacet' && filter) {
    return [
      <SearchFiltersSidebarFilterPill
        key={facet.id}
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        text={facet.optionTitles[filter]}
        clearFilter={() => setFilter(facet.id, null)}
      />,
    ];
  }

  return [];
}
