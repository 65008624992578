import { endOfDay, getUnixTime, parse, parseISO, startOfDay } from 'date-fns';
import { format, fromZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from 'components/Box';
import { DatePicker } from 'components/Date/DatePicker';
import { Flex } from 'components/Flex';
import { Label } from 'components/Label';
import { useLatest } from 'hooks/useLatest';
import { SearchFilterSection } from 'modules/search/components/FiltersSidebar/SearchFilterSection';
import { actionDateFacet } from 'modules/search/constants/searchFacets/actionDateFacet';
import { SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { IDEALIST_TIME_ZONE } from 'utils/constants/general/idealistTimeZone';
import { objectEmpty } from 'utils/functional';
import { getTimezoneFromLatLong, getUserTimezone } from 'utils/timezones';

type Props = {
  facet: typeof actionDateFacet;
  filtersById: SearchFiltersById;
  setSearchFilter: (
    facetId: SearchFacetId,
    value?: string | Record<string, unknown> | null,
  ) => void;
  expanded: boolean;
  searchLocation: SearchLocation | null | undefined;
  searchLocationActive: boolean;
  toggleExpanded: () => void;
};

export function SearchFiltersSidebarDateFacet({
  facet,
  filtersById,
  setSearchFilter,
  expanded,
  searchLocation,
  searchLocationActive,
  toggleExpanded,
}: Props) {
  const timezone =
    searchLocationActive && searchLocation?.latitude && searchLocation.longitude
      ? getTimezoneFromLatLong(
          searchLocation.latitude,
          searchLocation.longitude,
        )
      : getUserTimezone();

  // Access the search filter's values
  const [filterStart, filterEnd] = useMemo(() => {
    // TODO: Fix this the next time the file is edited.
    // @ts-expect-error fix types
    const filter: Record<string, string | null> = filtersById[facet.id]; // { endsGT: '1675803600', startsLT: '1676494799' }

    const startValue =
      !objectEmpty(filter) && typeof filter === 'object' ? filter.endsGT : null;
    const endValue =
      !objectEmpty(filter) && typeof filter === 'object'
        ? filter.startsLT
        : null;

    // Algolia stores Unix timestamps in seconds, Date() uses milliseconds
    return [
      startValue ? Number(startValue) * 1000 : null,
      endValue ? Number(endValue) * 1000 : null,
    ];
  }, [filtersById, facet]);

  const [pickerStart, setPickerStart] = useState<string | null>();
  const pickerStartRef = useLatest(pickerStart);

  const [pickerEnd, setPickerEnd] = useState<string | null>();
  const pickerEndRef = useLatest(pickerEnd);

  /**
   * This component does not calculate the new date properly when the timezone changes
   *
   * However, this bug was being masked by the UI not updating properly and the URL completely losing track of the parameters
   */
  const skipNextUIUpdateRef = useRef(false);

  useEffect(() => {
    if (skipNextUIUpdateRef.current) {
      skipNextUIUpdateRef.current = false;
      return;
    }

    const newPickerStart = filterStart
      ? format(filterStart, 'yyyy-MM-dd')
      : null;
    if (newPickerStart !== pickerStartRef.current)
      setPickerStart(newPickerStart);

    const newPickerEnd = filterEnd ? format(filterEnd, 'yyyy-MM-dd') : null;
    if (newPickerEnd !== pickerEndRef.current) setPickerEnd(newPickerEnd);
  }, [filterEnd, filterStart, pickerEndRef, pickerStartRef]);

  const onChangeStart = useCallback(
    (dateString?: string | null) => {
      const date = dateString ? parseISO(dateString) : null;
      const pickerDate = date ? format(date, 'yyyy-MM-dd') : null;

      setPickerStart(pickerDate);

      setSearchFilter('actionDateFacet', {
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        ...filtersById.actionDateFacet,
        // Convert a system date to equivalent in UTC
        endsGT: date
          ? getUnixTime(
              fromZonedTime(startOfDay(date), timezone || IDEALIST_TIME_ZONE),
            )
          : null,
      });
    },
    [setSearchFilter, filtersById, timezone],
  );

  const onChangeEnd = useCallback(
    (dateString: string | null) => {
      const date = dateString ? parseISO(dateString) : null;
      const pickerDate = date ? format(date, 'yyyy-MM-dd') : null;

      setPickerEnd(pickerDate);

      setSearchFilter('actionDateFacet', {
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error fix types
        ...filtersById.actionDateFacet,
        // Convert a system date to equivalent in UTC
        startsLT: date
          ? getUnixTime(
              fromZonedTime(endOfDay(date), timezone || IDEALIST_TIME_ZONE),
            )
          : null,
      });
    },
    [setSearchFilter, filtersById, timezone],
  );

  const updateSearchFilters = useCallback(
    (tz: string) => {
      skipNextUIUpdateRef.current = true;

      const endDate = pickerEnd
        ? parse(pickerEnd, 'yyyy-MM-dd', new Date())
        : undefined;

      const startDate = pickerStart
        ? parse(pickerStart, 'yyyy-MM-dd', new Date())
        : undefined;

      setSearchFilter('actionDateFacet', {
        startsLT: endDate
          ? getUnixTime(fromZonedTime(endOfDay(endDate), tz))
          : null,
        endsGT: startDate
          ? getUnixTime(fromZonedTime(startOfDay(startDate), tz))
          : null,
      });
    },
    [pickerEnd, pickerStart, setSearchFilter],
  );

  const prevTimezoneRef = useRef(timezone);
  useEffect(() => {
    if (!timezone) return;

    // Timezone changed, update the 'start' and 'end' search filters
    // to consider the new timezone
    if (timezone !== prevTimezoneRef.current) {
      updateSearchFilters(timezone);
    }

    prevTimezoneRef.current = timezone;
  }, [timezone, searchLocationActive, updateSearchFilters]);

  return (
    <SearchFilterSection
      title={facet.title}
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      data-qa-id={`side-filter-facet-${facet.name}`}
      data-qa-filter-value={[filterStart, filterEnd].join()}
      count={[filterStart, filterEnd].filter((a) => Boolean(a)).length}
      noMask
    >
      <Flex flexDirection="column" mt="14px">
        <Box mt="10px">
          <Label data-qa-id="endsGT-label">{getText('Start Date')}</Label>
          <DatePicker
            value={pickerStart}
            onChange={onChangeStart}
            placeholder={getText('Select')}
            name="endsGT"
            disablePastDates
            isTimeZoneAware
          />
        </Box>
        <Box mt="10px">
          <Label>{getText('End Date')}</Label>
          <DatePicker
            value={filterEnd ? pickerEnd : null}
            onChange={onChangeEnd}
            placeholder={getText('Select')}
            name="startsLT"
            disablePastDates
            isTimeZoneAware
          />
        </Box>
      </Flex>
    </SearchFilterSection>
  );
}
