import { ReactNode } from 'react';

import { SearchFilterSectionList } from 'modules/search/components/FiltersSidebar/SearchFilterSectionList';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';

type Props = {
  currentSection: string | null;
  deselectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  facet: SearchFacet;
  filtersById: SearchFiltersById;
  keywords: string[];
  header?: ReactNode;
  searchableFacets: string[];
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  selectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  setSearchFilter: (facetId: SearchFacetId, value?: string) => void;
  toggleCurrentSection: (index: null | string) => void;
  footer?: ReactNode;
};

export function SearchFilterSectionArrayFacet({
  currentSection,
  deselectSearchFilterOption,
  facet,
  filtersById,
  keywords,
  header,
  searchableFacets,
  selectAllSearchFilterOptions,
  selectSearchFilterOption,
  setSearchFilter,
  toggleCurrentSection,
  footer,
}: Props) {
  return (
    <SearchFilterSectionList
      key={facet.id}
      facet={facet}
      filtersById={filtersById}
      keywords={keywords}
      onSetSearchFilter={setSearchFilter}
      onSelectSearchFilterOption={selectSearchFilterOption}
      onDeselectSearchFilterOption={deselectSearchFilterOption}
      onSelectAllSearchFilterOptions={selectAllSearchFilterOptions}
      hasOptionFilter={searchableFacets.includes(facet.id)}
      toggleExpanded={() => toggleCurrentSection(facet.name)}
      expanded={currentSection === facet.name}
      header={header}
      footer={footer}
    />
  );
}
