import { Label } from 'components/Label';
import { BodySmall } from 'components/Text/BodySmall';
import { TabContext } from 'containers/TabContext';
import { colors } from 'theme/theme';

import {
  OptionContainer,
  SearchFacetMultiOptionTextTruncate,
  StyledInput,
} from './SearchFacetMultiOption.styled';

type Props = {
  title: string;
  isSelected: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggle: (...args: Array<any>) => any;
  value: string;
};

export function SearchFacetMultiOption({
  title,
  isSelected,
  toggle,
  value,
}: Props) {
  return (
    <OptionContainer>
      <TabContext.Consumer>
        {(isTabbing) => (
          <Label
            data-qa-id="side-filter"
            data-qa-selected={isSelected}
            data-qa-value={value}
          >
            <StyledInput
              type="checkbox"
              onChange={toggle}
              checked={isSelected}
              $isTabbing={isTabbing}
            />
            <SearchFacetMultiOptionTextTruncate>
              <BodySmall color={colors.mediumContentGrey}>{title}</BodySmall>
            </SearchFacetMultiOptionTextTruncate>
          </Label>
        )}
      </TabContext.Consumer>
    </OptionContainer>
  );
}
