import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { Text } from 'components/Text/Text';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { gutterWidth } from 'theme/theme';
import { hasParam } from 'utils/url/hasParam';
import { removeParam } from 'utils/url/removeParam';

import {
  ClearHolder,
  ClearMobileHolder,
  FiltersHolder,
  StyledSearchFiltersSidebar,
} from './SearchFiltersSidebar.styled';
import { SearchFiltersSidebarSelectedFilters } from './SearchFiltersSidebarSelectedFilters';

type Props = {
  searchLocation?: SearchLocation | null | undefined;
  filtersById: SearchFiltersById;
  setJobSynonym?: (jobSynonym: string) => void;
  jobSynonym?: { id: string; name: string };
  facetsToRender: SearchFacet[];
  defaultFiltersSet: boolean;
  setSearchFilter: (
    facetId: SearchFacetId,
    value:
      | string
      | { endsGT: string | undefined; startsLT: string | undefined }
      | null
      | undefined,
  ) => void;
  deselectSearchFilterOption: (facetId: SearchFacetId, option: string) => void;
  selectAllSearchFilterOptions: (facetId: SearchFacetId) => void;
  clearAllFilters: () => void;
  setSearchLocation?: (
    searchLocation: SearchLocation | null | undefined,
    shouldEnqueueSearch?: boolean | null,
  ) => void;
  searchLocationActive: boolean;
  header?: ReactNode;
  facetsNode: ReactNode;
  toggleCurrentSection: (index: null | string) => void;
};

export function SearchFiltersSidebar({
  searchLocation,
  filtersById,
  jobSynonym,
  facetsToRender,
  defaultFiltersSet,
  setJobSynonym,
  setSearchFilter,
  deselectSearchFilterOption,
  selectAllSearchFilterOptions,
  clearAllFilters,
  setSearchLocation,
  header,
  searchLocationActive,
  facetsNode,
  toggleCurrentSection,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();

  const clearFilters = () => {
    clearAllFilters();
    toggleCurrentSection(null);
    if (hasParam(location.search, 'synonymId')) {
      const updatedUrl = `${location.pathname}?${removeParam(
        location.search.replace('?', ''),
        'synonymId',
      )}`;
      navigate(updatedUrl, { replace: true });
    }
  };

  return (
    <StyledSearchFiltersSidebar data-qa-id="search-filters">
      <FiltersHolder>
        <ClearHolder>
          <Text styleAs="h5" tag="p" mb={0}>
            <span style={{ display: 'flex', gap: 20 }}>
              <span>{getText('Filters')}</span>

              {!defaultFiltersSet && (
                <Button
                  type="button"
                  variant="link"
                  data-qa-id="clear-search-filters"
                  onClick={(e) => {
                    e.preventDefault();
                    clearFilters();
                  }}
                >
                  {getText('Clear')}
                </Button>
              )}
            </span>
          </Text>
        </ClearHolder>

        {facetsToRender.length > 0 && (
          <ClearMobileHolder>
            {defaultFiltersSet ? (
              <Text color="lightContentGrey">Clear</Text>
            ) : (
              <StyledNativeLinkWithRef
                href="#"
                data-qa-id="clear-search-filters"
                onClick={(e) => {
                  e.preventDefault();
                  clearFilters();
                }}
              >
                {getText('Clear')}
              </StyledNativeLinkWithRef>
            )}
          </ClearMobileHolder>
        )}
        {!defaultFiltersSet && (
          <Box
            mx={[0, null, null, '-6px']}
            mt={[0, null, null, -16]}
            pb={24}
            width={['100%', null, null, 'calc(100% + 12px)']}
            px={[gutterWidth / 2, null, null, 0]}
          >
            <SearchFiltersSidebarSelectedFilters
              filtersById={filtersById}
              facets={facetsToRender}
              clearFilter={deselectSearchFilterOption}
              setFilter={setSearchFilter}
              jobSynonym={jobSynonym}
              setJobSynonym={setJobSynonym}
              selectAllSearchFilterOptions={selectAllSearchFilterOptions}
              searchLocation={searchLocation}
              setSearchLocation={setSearchLocation}
              searchLocationActive={searchLocationActive}
              data-qa-id="selected-filters"
            />
          </Box>
        )}

        {header}

        {facetsNode}
      </FiltersHolder>
    </StyledSearchFiltersSidebar>
  );
}
