import { useEffect, useMemo } from 'react';

import { Box } from 'components/Box';
import { LegacyTertiaryButton } from 'components/LegacyButton/LegacyTertiaryButton';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';

import { StyledLabel } from './SearchFiltersSidebarLocationFacet.styled';
import { SearchRadiusSelector } from './SearchRadiusSelector';

type Props = {
  filtersById: SearchFiltersById;
  hideSearchLocationAndRadius: boolean;
  searchLocationActive: boolean;
  searchLocation: SearchLocation | null | undefined;
  setSearchLocation: (
    searchLocation: SearchLocation | null | undefined,
    shouldEnqueueSearch?: boolean | null,
  ) => void;
  showRadiusSection: boolean;
  searchRadius: string | undefined;
  setSearchRadius: ((searchRadius: string) => void) | undefined;
};

export function SearchFiltersSidebarLocationFacet({
  filtersById,
  hideSearchLocationAndRadius,
  searchLocationActive,
  searchLocation,
  setSearchLocation,
  showRadiusSection,
  searchRadius,
  setSearchRadius,
}: Props) {
  const isOnsiteSelected = useMemo(() => {
    const value = filtersById.locationTypeFacet;
    return (
      value === 'ONSITE' ||
      (Array.isArray(value) && value.length === 1 && value[0] === 'ONSITE')
    );
  }, [filtersById.locationTypeFacet]);

  const includeState =
    !isOnsiteSelected && searchLocation?.countryCode === 'US';
  const includeCountry = !isOnsiteSelected;

  useEffect(() => {
    if (searchRadius === 'country' && !includeCountry)
      setSearchRadius?.('auto');
    else if (searchRadius === 'state' && !includeState)
      setSearchRadius?.('auto');
  }, [includeCountry, includeState, searchRadius, setSearchRadius]);

  return (
    <>
      {!hideSearchLocationAndRadius && (
        <>
          <StyledLabel>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {getText('Location')}
              {searchLocationActive && (
                <Box
                  data-qa-id="clear-location"
                  component={LegacyTertiaryButton}
                  fontSize="inherit !important"
                  onClick={() => setSearchLocation(null)}
                >
                  {getText('Search Everywhere')}
                </Box>
              )}
            </Box>
          </StyledLabel>
          <LocationInput
            placeholder={getText('Everywhere')}
            locationText={searchLocation ? searchLocation.text : ''}
            onChange={setSearchLocation}
            showClearButton
            hasRemoteOptions
            styleVariant="default"
            affixVariant="transparent"
          />
        </>
      )}

      <Box px={0} mt={2}>
        {showRadiusSection && setSearchRadius && searchRadius && (
          <>
            <StyledLabel>{getText('Radius')}</StyledLabel>
            <SearchRadiusSelector
              radius={searchRadius}
              setSearchRadius={setSearchRadius}
              includeState={includeState}
              includeCountry={includeCountry}
            />
          </>
        )}
      </Box>
    </>
  );
}
